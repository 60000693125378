<template>
  <div class="con2_">
    <div data-v-ce543f14 class="team_">
      <div
        data-v-54c8b977
        data-v-ce543f14
        class="page_c_ addAccount_ form_custom_">
        <div data-v-54c8b977 class="table_">
          <div data-v-54c8b977 class="up_">
            <div data-v-54c8b977 class="title_">基本资料</div>
            <form
              data-v-54c8b977
              class="ant-form ant-form-horizontal ant-form-hide-required-mark">
              <el-form
                label-width="100px"
                :model="form"
                ref="form"
                :rules="rules">
                <el-form-item label="登陆账号">
                  <el-input
                    v-model="form.account"
                    class="item"
                    disabled></el-input>
                </el-form-item>
                <el-form-item label="团队名称">
                  <el-input
                    v-model="form.name"
                    class="item"
                    :disabled="disFlag"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                  <el-input
                    v-model="form.contactName"
                    class="item"
                    :disabled="disFlag"></el-input>
                </el-form-item>
                <el-form-item label="联系人电话" prop="contactPhone">
                  <el-input
                    v-model="form.contactPhone"
                    class="item"
                    :disabled="disFlag"></el-input>
                  <el-button
                    @click="editD"
                    v-if="disFlag === true"
                    style="margin-left: 36px;"
                    class="ant-btn ant-btn-primary"
                    >编辑</el-button
                  >
                  <el-button
                    @click="onSubmit('form')"
                    v-if="disFlag === false"
                    style="margin-left: 36px;"
                    class="ant-btn ant-btn-primary"
                    >保存</el-button
                  >
                </el-form-item>
              </el-form>
              <!--              头像-->
              <div data-v-54c8b977 class="photo_wrap_">
                <div data-v-54c8b977 class="photo_">
                  <el-upload
                    class="avatar-uploader"
                    :action="getUrl()"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="form.url !== null"
                      :src="imageUrl"
                      class="avatar"
                      alt/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <div data-v-54c8b977 class="c_">点击修改头像</div>
              </div>
            </form>
          </div>
        </div>
        <div data-v-616188e4 data-v-54c8b977 class="comp_"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadHost } from "@/environment";
import { mapActions } from "vuex";
export default {
  name: "basicInfo",
  data() {
    // 手机号验证
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    return {
      imageUrl: "",
      user: {},
      form: {
        id: null,
        contactName: null,
        contactPhone: null,
        name: null,
        account: null
      },
      disFlag: true,
      rules: {
        contactPhone: [
          { required: true, validator: checkPhone, trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions("user", ["details", "edit"]),
    load() {
      this.user = this.$store.getters["user/userDetails"];
      this.details(this.user.userId).then(res => {
        let data = res.data.data;
        this.form = data;
        this.form.account = data.username;
        this.imageUrl = downloadHost + this.form.url;
      });
      // this.form.id = this.user.userId;
      // this.form.account = this.user.username;
      // this.form.contactName = this.user.contactName;
      // this.form.contactPhone = this.user.contactPhone;
      // this.form.name = this.user.name;
      // this.imageUrl = downloadHost + this.user.url;
    },
    editD() {
      this.disFlag = false;
    },
    getUrl() {
      return process.env.VUE_APP_BASE_API + "/file/upload";
    },
    //保存
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.edit(this.form).then(res => {
            if (res.data.code === 2000) {
              //先跳出一个成功的提醒
              this.$message.success("修改成功！");
              this.load();
              this.disFlag = true;
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = downloadHost + res.data;
      console.log("imageUrl===", res);
      //修改url
      this.edit({ id: this.user.userId, url: res.data });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style src="../../../../theme/chunk-vendo.css" scoped></style>
<style src="../../../../theme/chunk.css" scoped></style>
<style src="../../../../theme/chunk_a.css" scoped></style>
<style src="../../../../theme/chunk_0.css" scoped></style>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.item {
  width: 400px;
}
</style>
