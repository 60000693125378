import { render, staticRenderFns } from "./basicInfo.vue?vue&type=template&id=17b5e922&scoped=true&"
import script from "./basicInfo.vue?vue&type=script&lang=js&"
export * from "./basicInfo.vue?vue&type=script&lang=js&"
import style0 from "../../../../theme/chunk-vendo.css?vue&type=style&index=0&id=17b5e922&scoped=true&lang=css&"
import style1 from "../../../../theme/chunk.css?vue&type=style&index=1&id=17b5e922&scoped=true&lang=css&"
import style2 from "../../../../theme/chunk_a.css?vue&type=style&index=2&id=17b5e922&scoped=true&lang=css&"
import style3 from "../../../../theme/chunk_0.css?vue&type=style&index=3&id=17b5e922&scoped=true&lang=css&"
import style4 from "./basicInfo.vue?vue&type=style&index=4&id=17b5e922&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b5e922",
  null
  
)

export default component.exports